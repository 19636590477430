import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Ingresa tus datos',
    component: () => import('../views/Home.vue')
  },
  {
    path:'/constancia',
    name:'constancia',
    component:() => import('../views/LicenseData.vue'),
    beforeEnter(to,from,next){
      if(localStorage.getItem("usrData")){
        next();
      }else{
        next("/");
      }
    }
  },
  {
    path:'/terminos-y-condiciones',
    name:'Terminos y condiciones',
    component:() => import('../views/terms&Conditions/GeneralTerms.vue')
  },
  {
    path:'/terminos-y-condiciones-dgaf',
    name:'Terminos y condiciones DGAF',
    component:() => import('../views/terms&Conditions/dgafTerms.vue')
  },
  {
    path:'/terminos-y-condiciones-artf',
    name:'Terminos y condiciones ARTF',
    component:() => import('../views/terms&Conditions/artfTerms.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next )=>{
  let aux = from;
  aux.fullPath;
  document.title =  `${to.name} - ${ process.env.VUE_APP_TITLE }` ;
  next();
});

export default router
