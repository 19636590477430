import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from "../router"
import Swal from 'sweetalert2'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    licencia: {
      transporte: 0,
      nombre: "jluoi",
      appellido_paterno: "",
      appellido_materno: "",
      rfc: "",
      curp: "",
      nacionalidad: "",
      num_licencia: "",
      inicio_vigencia: "",
      fin_vigencia: "",
      antiguedad: "",
      text_validez: "",
      validez: "",
      tipo_licencia: "",
      tipo_licencia_articulo: "",
      num_expediente_med: 0,
      lugar_tramite: "",
      nombre_director: "",
      cargo_director: "",
      media: {
          image: {
              mime: "",
              base64: ""
          },
          firma: {
              mime: "",
              base64: ""
          },
          firma_autoriza: {
              mime: "",
              base64: ""
          }
      },
      observaciones: {
          
      },
      leyenda: "",
      leyenda2:""

  }
  },
  mutations: {
    usrData(state,usrData){
      state.licencia = usrData
    }
  },
  actions: {
    login({commit},res){
      axios
          .post("api/v1/SignUpPortal/", {
            curp: res.data.curp,
            telefono: res.data.phone,
            contrasena: res.data.password,
            expediente: res.data.eMedico,
          })
          .then(function (response) {
            console.log("response: ",response.data.licencia);
            localStorage.removeItem('usrData');
            localStorage.setItem('usrData',JSON.stringify(response.data.licencia));
            commit('usrData',{
              usrData:response.data.licencia
            })
            router.replace('/constancia')
            //this.$store.dispatch('login')
          })
          .catch(function (error) {
            var message = "";
            if(error.response.status === 500){
              message = "Error de servidor favor de contactar a la Secretaría de Comunicaciones y Transportes";
            }else{
              message = error.response.data.message;
            }
            Swal.fire({
              icon: 'error',
              title: 'LFD',
              text: message
            })
          });
    }
  },
  modules: {
  }
})
