import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vuelidate from 'vuelidate'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'),{
  moment
});
console.log(Vue.moment().locale()) ///es
Vue.use(Vuelidate);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
